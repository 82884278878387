<template>
  <section>
    <b-modal
      :show="show_banner"
      :title="banner.title"
      @close="closeModal"
    >
      {{ banner.message }}
      <a
        v-if="banner.mail.length"
        :href="`mailto:${banner.mail}`"
        >{{ banner.mail }}</a
      >
    </b-modal>
  </section>
</template>

<script setup>
import { useStore } from 'vuex'
import BModal from '@/components/global/modals/bModal.vue'
import { computed, ref, watch } from 'vue'

const store = useStore()

const show_banner = ref(false)

const banner = computed(() => store.state.banner)

watch(
  () => banner.value.message,
  () => {
    show_banner.value = !!banner.value.message.length
  },
)
//mutations
const BANNER = text => {
  store.commit('BANNER', text)
}

const closeModal = () => {
  BANNER({ title: '', message: '', mail: '' })
  show_banner.value = false
}
</script>

<style scoped lang="sass">
@use "@/assets/sass/style.sass"
</style>

<template>
  <section>
    <router-view />
    <toast-container></toast-container>
    <banner-container></banner-container>
  </section>
</template>

<script setup>
import ToastContainer from '@/components/plugins/Toast'
import BannerContainer from '@/components/plugins/Banner'
import { useI18n } from 'vue-i18n'
import { onMounted } from 'vue'

const i18n = useI18n()

onMounted(() => {
  i18n.locale.value = localStorage.getItem('language') ? localStorage.getItem('language') : 'ru'
})
</script>

<style lang="sass">
@mixin font($fname, $fstyle, $fweight, $furl)
  @font-face
    font-family: $fname
    font-style: $fstyle
    font-weight: $fweight
    // font-display: swap // Uncomment to initially display system fonts
    src: local($fname), url($furl) format('woff2')

@include font('Lato', normal, 300, './assets/fonts/Lato/Lato-Light.ttf')
@include font('Lato', italic, 300, './assets/fonts/Lato/Lato-LightItalic.ttf')
@include font('Lato', normal, 400, './assets/fonts/Lato/Lato-Regular.ttf')
@include font('Lato', italic, 400, './assets/fonts/Lato/Lato-Italic.ttf')
@include font('Lato', normal, 500, './assets/fonts/Lato/Lato-Medium.ttf')
@include font('Lato', italic, 500, './assets/fonts/Lato/Lato-MediumItalic.ttf')
@include font('Lato', normal, 700, './assets/fonts/Lato/Lato-Bold.ttf')
@include font('Lato', italic, 700, './assets/fonts/Lato/Lato-BoldItalic.ttf')
@include font('Lato', normal, 800, './assets/fonts/Lato/Lato-Heavy.ttf')
@include font('Lato', italic, 800, './assets/fonts/Lato/Lato-HeavyItalic.ttf')

#app
  font-family: 'Lato', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: left
  line-height: 1.5

*
  font-family: 'Lato', sans-serif


body
  padding: 0
  margin: 0
</style>
